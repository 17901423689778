






import { Component, Vue }   from 'vue-property-decorator'

import { organisationDocumentsModule }  from '@/store'
import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'

import DocumentDetailNotesPartial from '@/components/document/Notes.vue'

@Component({
  components: {
    DocumentDetailNotesPartial
  }
})
export default class DocumentDetailNotes extends Vue {

  private message: string = ''
  private loading: boolean = false

  private get detail(): OrganisationDocumentResource | null {
    return organisationDocumentsModule.detail
  }

}
